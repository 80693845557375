.cardPrimary {
  background: #FFFFFF;
  box-shadow: 2px 2px 15px rgba(44, 62, 80, 0.2);
  border-radius: 10px;
  padding: 24px 32px;
}

.cardSecondary {
  background: rgb(157, 163, 174, 0.1);
  border-radius: 20px;
  padding: 22px;
}

.heading {
  font-size: 20px;
  font-weight: 700;
}

.headingPrimary {
  color: #3F6DB5;
  margin-bottom: 18px;
}

.headingSecondary {
  color: #2C333A;
}

.headingSecondaryCard {
  color: #2C333A;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}

.contentSecondaryCard {
  font-weight: 400;
  font-size: 16px;
  color: #2C333A;
  margin-bottom: 8px;
}

.linkSecondaryCard, .linkSecondaryCard:hover {
  font-weight: 600;
  font-size: 16px;
  color: #3F6DB5;
  display: flex;
  gap: 8px;
  text-decoration: none;
}

.subHeading {
  font-size: 16px;
  font-weight: 400;
  color: #7A7A7A;
}

.list {
  margin: 0;
  padding: 0 1rem;
}

.listItem {
  margin-top: 1rem;
}

.container {
  padding: 40px 32px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 26px;
  margin-top: 24px;
  margin-bottom: 30px;
}

.subject {
  margin-top: 0.25rem; /* 4px */
  margin-bottom: 0.25rem; /* 4px */
  color: #2C333A;
  font-weight: 600;
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  letter-spacing: 0.025em;
}

.kpiContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

.kpiValue {
  font-weight: 700;
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.kpiDate {
  font-weight: 300;
}

.incomeSvg {
  width: 1.5rem;
  margin-right: 0.75rem;
  color: green
}

.outcomeSvg {
  width: 1.5rem;
  margin-right: 0.75rem;
  color: red
}

.controlsTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
}

.controlsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  user-select: none;
  color: #3F6DB5;
}

.controlsText {
  margin: 0px;
  font-weight: 300;
}

.controlsArrow {
  margin: 0px 20px;
  margin-bottom: 7px;
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
  cursor: pointer;
  vertical-align: middle;
}

.controlsYear {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  font-weight: 700;
}

.balanceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.bankIcon {
  color: gray;
  display: inline;
  margin-right: 12px;
}

.balanceText {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  font-weight: 700;
  color: #2C333A;
}