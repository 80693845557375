/* COLORS */
:root {
  --grey: #707070;
  --light-grey: #f9f9f9;
  --primary-blue: #2C333A;
  --light-primary-grey: #a9b1b9;
  --primary-pinv: #3F6DB5;
  --white: #ffffff;
  --danger: #d72638;
}

/* GENERAL */
body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: var(--white);
  color: var(--primary-blue) !important;
}

.u-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.u-center-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.u-center-horizontal-inner {
  display: table;
  margin: 0 auto;
}

.form-response-error {
  margin-top: 20px;
  color: var(--danger);
  font-style: italic;
}

.success {
  color: var(--primary-pinv);
}

/* MARGIN */
.m-t-40 {
  margin-top: 40px;
}

/* TEXT */
.u-text-left {
  text-align: left !important;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right !important;
}

.text-action {
  font-weight: 700;
  color: var(--primary-pinv);
}

h4 {
  font-weight: 700;
}

/* CONTAINER */
.cont-100vh {
  min-height: 100vh;
}
.cont-100vh .cont-grey {
  background-color: var(--light-grey);
  min-width: 800px;
  padding: 40px 50px;
}
.cont-100vh .cont-grey img {
  max-width: 130px;
  margin-bottom: 40px;
}
.cont-100vh .cont-grey p {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}
.cont-100vh .cont-grey button {
  margin-top: 40px;
  min-width: 246px;
}

@media only screen and (max-width: 991px) {
  .cont-100vh .cont-grey {
    width: 100%;
    min-width: inherit;
  }
}

@media only screen and (max-width: 418px) {
  .cont-100vh .cont-grey {
    padding: 50px 25px;
  }
  .cont-100vh button {
    width: 100%;
    min-width: inherit !important;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 360px) {
  .cont-100vh {
    height: auto;
  }
  .cont-100vh .container {
    padding: 0;
  }
  .cont-100vh .cont-grey {
    padding: 50px 25px;
  }
  .cont-100vh .u-center-center {
    position: relative;
    top: 0;
    left: 0;
    transform: inherit;
  }
}

/* BUTTONS */
.btn-pinv {
  background-color: var(--primary-blue);
  border-radius: 0;
  font-weight: 700;
  padding: 15px 50px;
  text-transform: uppercase;
  border: 0;
}

.btn-pinv:hover, .btn-pinv:active, .btn-pinv:focus, .btn-pinv:visited {
  background-color: var(--primary-pinv) !important;
  color: var(--primary-blue) !important;
  box-shadow: none !important;
}

.btn-active {
  background-color: var(--primary-blue) !important;
  color: var(--white) !important;
}

.btn-back {
  background-color: var(--primary-pinv);
  color: var(--white);
  padding: 15px 30px;
  margin-bottom: 20px;
}
.btn-back i {
  margin-right: 10px;
}
.btn-back:hover {
  opacity: 0.5;
}

/* FORM */
form label {
  color: var(--primary-blue);
  font-weight: 700;
  margin-top: 10px;
}
form .form-control {
  background-color: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid var(--primary-pinv);
}
form .form-control:active, form .form-control:focus {
  background-color: transparent;
  border-bottom-color: var(--primary-blue);
  box-shadow: none;
}
form a {
  color: var(--grey);
  transition: 0.5s;
}
form a p {
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 0;
}
form a:hover {
  text-decoration: none;
  color: var(--primary-pinv);
}

.input-office-name .form-group {
  margin-right: 0 !important;
}
.input-office-name .form-group input {
  max-width: 400px;
  width: 400px !important;
  border: 2px solid var(--primary-pinv);
}
.input-office-name .form-group input:active, .input-office-name .form-group input:focus {
  background-color: transparent;
  border-color: var(--primary-blue);
  box-shadow: none;
}
.input-office-name .btn {
  border-radius: 0;
  background-color: var(--primary-pinv);
  border: 1px solid var(--primary-pinv);
}
.input-office-name .btn:active, .input-office-name .btn:focus {
  background-color: var(--primary-pinv) !important;
  border-color: var(--primary-pinv) !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 575px) {
  .input-office-name {
    position: relative;
  }
  .input-office-name .form-group {
    width: 100%;
  }
  .input-office-name .form-group input {
    max-width: inherit;
    width: calc(100% - 42px) !important;
  }
  .input-office-name .btn {
    position: absolute;
    right: 0px;
    top: 54px;
  }
}

/* NAV */
nav {
  background-color: var(--primary-pinv);
  padding: 20px;
  position: fixed;
  width: 100%;
  z-index: 10;
}
nav p {
  text-align: right;
  margin: 0;
  font-weight: 700;
  color: var(--white);
}
nav p a {
  color: var(--primary-blue) !important;
  margin-left: 5px;
  text-decoration: underline !important;
}

.nav-responsive-show {
  display: none;
}

@media only screen and (max-width: 1313px) {
  .nav-responsive-show {
    display: block;
  }
}

.bm-burger-button {
  position: fixed;
  width: 35px;
  top: 15px;
  left: 20px;
}

.bm-cross-button {
  left: calc(30% - 40px);
  z-index: 20;
  top: 14px !important;
}

.bm-item-list {
  width: 30%;
  background-color: var(--light-primary-grey);
  padding: 0;
}

@media only screen and (max-width: 860px) {
  .bm-item-list {
    width: 50%;
  }
  .bm-cross-button {
    left: calc(50% - 40px);
  }
}

@media only screen and (max-width: 400px) {
  .bm-item-list {
    width: 65%;
  }
  .bm-cross-button {
    left: calc(65% - 40px);
  }
}

/* INDEX */
.section-navigation {
  overflow-x: hidden;
  position: relative;
}
.section-navigation .lateral-navigation {
  position: fixed;
  background-color: var(--light-primary-grey);
  height: calc(100% - 64px);
  width: 14%;
  top: 64px;
}
.section-navigation .corpo {
  width: 86%;
  height: 100vh;
  margin-left: 14%;
  padding: 30px;
  padding-top: 100px;
}
.section-navigation .corpo .select-date {
  position: relative;
  width: 100%;
}
.section-navigation .corpo .select-date .cont-select-date {
  width: 250px;
  height: 100%;
  float: left;
}
.section-navigation .corpo .select-date .cont-select-date:last-child {
  margin-left: 20px;
}
.section-navigation .corpo .select-date .cont-select-date .react-datepicker-wrapper {
  margin-top: -37px;
  float: right;
}
.section-navigation .corpo .select-date .cont-select-date .react-datepicker-wrapper input {
  border: 2px solid var(--primary-pinv);
  color: var(--primary-pinv);
  padding-right: 7px;
  padding-left: 7px;
  width: 190px;
}
.section-navigation .corpo .select-date .cont-select-date .react-datepicker-wrapper input:focus {
  outline: none;
  border-color: var(--primary-blue)
}
.section-navigation .corpo .box-invoces {
  height: calc(100vh - 200px);
  margin-bottom: 20px;
}
@media only screen and (max-width: 991px) {
  .section-navigation .corpo .box-invoces {
    height: auto;
  }
}
.section-navigation .corpo .box-invoces .head {
  height: 78px;
  text-align: center;
  background-color: var(--primary-blue);
  color: var(--white);
  padding: 15px;
}
.section-navigation .corpo .box-invoces .head h5 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 800;
}
.section-navigation .corpo .box-invoces .body {
  background-color: var(--light-grey);
  border: 2px solid var(--primary-blue);
  height: calc(100% - 78px);
  overflow-y: scroll;
}
@media only screen and (max-width: 991px) {
  .section-navigation .corpo .box-invoces .body {
    height: auto;
  }
}
.section-navigation .corpo .box-invoces .body .prev-invoces {
  padding: 15px;
  border-bottom: 1px solid #bcbcbc;
  position: relative;
}
.section-navigation .corpo .box-invoces .body .prev-invoces h6 {
  font-weight: 800;
  font-size: 18px;
}
.section-navigation .corpo .box-invoces .body .prev-invoces .name {
  margin-bottom: 0px;
  font-size: 15px;
}
.section-navigation .corpo .box-invoces .body .prev-invoces .date {
  color: var(--grey);
  font-size: 14px;
  margin-bottom: 0;
}
.section-navigation .corpo .box-invoces .body .prev-invoces .actions {
  text-align: right;
}
.section-navigation .corpo .box-invoces .body .prev-invoces .actions img {
  cursor: pointer;
  max-width: 43px;
}
.section-navigation .corpo .box-invoces .body-no-clients {
  padding: 30px;
  min-height: inherit !important;
  max-height: inherit !important;
  overflow-y: inherit !important;
}

.filter-clients {
  background-color: var(--primary-blue);
  padding: 25px 15px;
}
.filter-clients .form-control {
  border: 2px solid var(--white);
  border-radius: 0;
  margin-bottom: 10px;
  background-color: transparent;
  color: var(--white);
}
.filter-clients .form-control:focus {
  box-shadow: none;
  outline: 0;
  border-color: var(--primary-pinv);
}

.list-clients {
  height: calc(100% - 240px);
  padding: 15px;
  overflow-y: scroll;
}
.list-clients button {
  width: 100%;
  border-radius: 0;
  border: 2px solid var(--primary-blue);
  background-color: transparent;
  color: var(--primary-blue);
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
  white-space: normal;
}
.list-clients button:hover, .list-clients button:active, .list-clients button:focus {
  background-color: var(--primary-blue);
      color: var(--white);
  box-shadow: none;
  outline: 0;
}

.custom-padding {
  height: 100%;
}
.custom-padding .filter-clients {
  padding-top: 60px;
}

@media only screen and (max-width: 1313px) {
  .section-navigation .nav-resposive {
    display: none;
  }
  .section-navigation .corpo {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 579px) {
  .select-date .cont-select-date:first-child {
    margin-right: 20px;
  }
  .select-date .cont-select-date:last-child {
    margin-left: 0px !important;
  }
}

/* DATEPICKER */
.react-datepicker {
  font-family: "Lato", sans-serif !important;
  border-color: var(--primary-blue) !important;
}
.react-datepicker .react-datepicker__day--selected {
  background-color: var(--primary-pinv) !important;
}
.react-datepicker .react-datepicker__triangle {
  border-bottom-color: var(--primary-blue) !important;
}
.react-datepicker .react-datepicker__header {
  background-color: var(--primary-blue);
}
.react-datepicker .react-datepicker__header .react-datepicker__current-month {
  color: var(--primary-pinv);
}
.react-datepicker .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
  color: var(--white);
}
.react-datepicker .react-datepicker__month .react-datepicker__week .react-datepicker__day--keyboard-selected {
  background-color: var(--primary-pinv);
  border-radius: 15px;
}
.react-datepicker .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
  border-radius: 15px;
}
.react-datepicker__close-icon::after {
  background-color: var(--primary-blue) !important;
}

/* LOADING */
.loading-cont {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}
.loading {
  margin: auto;
  border: 4px solid transparent;
  border-top: 4px solid var(--primary-blue);
  border-left: 4px solid var(--primary-blue);
  border-radius: 50%;
  width: 54px;
  height: 54px;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite; /* Safari */
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-modal-backdrop {
  background-color: var(--primary-blue) !important;
}

.loading-modal-content {
  background-color: transparent;
  border: none;
}

.loading-profile {
  margin: 0 0 0 8px;
  border-top: 4px solid var(--primary-pinv);
  border-left: 4px solid var(--primary-pinv);
  width: 28px;
  height: 28px;
}

@media only screen and (max-width: 1310px) {
  .version-mobile {
    position: absolute;
    left: 17px;
    top: 15px;
    color: var(--grey);
    font-size: 12px;
  }
  .version-lg {
    display: none;
  }
}

@media only screen and (min-width: 1310px) {
  .version-mobile {
    display: none;
  }
  .version-lg {
    font-size: 12px;
    float: left;
  }
}

.pinv_pagination {
  list-style-type: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 30px;
}

.pinv_pagination li {
  margin: 0 12px;
  font-size: 14px;
  color: var(--primary-blue);
}

.pinv_pagination li.selected a {
  color: var(--primary-pinv);
}

.pinv_pagination li.selected a:focus {
  outline: none;
  outline-offset: 0;
}

.mov-table-container {
  width: 100%;
  box-shadow: 5px 0px 10px 5px rgba(0, 0, 0, 0.05);
  border: 1px;
  border-color: rgba(0, 0, 0, 0.10);
  border-style: solid;
  border-radius: 10px;
}

.mov-table {
  width: 100%;
  border-radius: 10px;
  border-collapse: collapse;
  background-color: var(--white);
}

.mov-table th,
.mov-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #eaeaea;
}

.mov-table th {
  font-weight: bold;
}

.mov-table tr:last-child td {
  border-bottom: none;
}

.filters-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 30px;
}

@media only screen and (min-width: 1310px) {
  .filters-container {
    flex-direction: row;
  }
}
